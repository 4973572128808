import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'
import Preloader from '../../../components/Preloader'
import ReorderUIComponents from '../../../components/ReorderUIComponents'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { PAGE_NAME_MAPPING } from '../../../utils/constants'
import useUIManagement from '../../../shared/hooks/useUIManagement'

const HomePageUIConfig = () => {
  const {
    uiComponentsLoading,
    pageData,
    loading,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    onDragEnd,
    handleVisibilityToggle,
    updateComponents,
    isTenant
  } = useUIManagement('homePage')

  return (
    <>
      <h3>UI Management: {PAGE_NAME_MAPPING?.homePage}</h3>
      {!isTenant && (
        <Row>
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <ClientFilter
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>

          {selectedClient && (
            <Col className='d-flex align-self-center mt-2' lg={3}>
              <PortalFilter
                selectedClient={selectedClient}
                selectedPortal={selectedPortal}
                setSelectedPortal={setSelectedPortal}
              />
            </Col>
          )}
        </Row>)}

      {loading && <Preloader />}

      {pageData?.rows?.length > 0 && (
        <ReorderUIComponents
          data={pageData?.rows}
          onDragEnd={onDragEnd}
          updateComponents={updateComponents}
          handleVisibilityToggle={handleVisibilityToggle}
          loading={uiComponentsLoading}
          droppableId='home-page'
        />
      )}
    </>
  )
}

export default HomePageUIConfig
