import * as Yup from 'yup'

export const createSubCategorySchema = (name) => Yup.object().shape({
  subCategoryName: validateName(name),
  tenantGameCategoryId: Yup.string().required('Category is required'),
  imageUrl: Yup.mixed().required('A file is required!').test('File Size',
    'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/webp' ].includes(value.type))),
  isLabeledCategory: Yup.boolean(),
  labelIconUrl: Yup.mixed().when('isLabeledCategory', {
    is: true,
    then: Yup.mixed()
      .required('Label Icon is required!')
      .test('File Size', 'File size should be less than 1MB', 
        (value) => !value || (value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.', 
        (value) => !value || ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/webp'].includes(value.type)),
    otherwise: Yup.mixed().notRequired(),
  }),
})

export const editSubCategorySchema = (name, editLabelIconUrl) => Yup.object().shape({
  subCategoryName: validateName(name),
  tenantGameCategoryId: Yup.string().required('Category is required'),
  imageUrl: Yup.mixed()
    .test('File Size',
      'File Size Should be Less Than 1MB',
      (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/webp'].includes(value.type))),
  isLabeledCategory: Yup.boolean(),
  labelIconUrl: Yup.mixed().when('isLabeledCategory', {
    is: true,
    then: Yup.mixed()
      .test(
        'Label Icon is required!',
        'Label Icon is required!',
        (value) => editLabelIconUrl || (!!value)
      )
      .test('File Size', 'File size should be less than 1MB', 
        (value) => !value || (value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.', 
        (value) => !value || ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/webp'].includes(value.type)),
    otherwise: Yup.mixed().notRequired(),
  }),
})

const validateName = (name) => {
  const validationObject = {}
  for (const file in name) {
    validationObject[file] = Yup
      .string()
      .required('Sub Category Name Required').nullable()
  }
  return Yup.object(validationObject)
}
