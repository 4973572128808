import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { AMOUNT_TYPE, statusType, tableHeaders } from '../../pages/Super-Admin/CasinoTransactions/constants'
import { getDateTime } from '../../utils/dateFormatter'
import Trigger from '../OverlayTrigger'
import './CasinoTransaction.scss'
import { TotalSumAmount } from './totalsumAmount'
import sortArrowIcon from '../SortArrowsIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

const CasinoTransactionsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  transactions,
  isTenant,
  loading = false,
  userDetail = false,
  sortBy,
  setSortBy,
  over,
  setOver,
  bannedGames,
  showBannedGameAmount,
  userData,
  showTotalAmount
}) => {
  const getBonusId = (bonusId) => { if (bonusId) return <>({bonusId})</> }

  return (
    <>
      <TotalSumAmount
        userDetail={userDetail}
        transactions={transactions}
        loading={loading}
        bannedGames={bannedGames}
        showBannedGameAmount={showBannedGameAmount}
        userData={userData}
        showTotalAmount={showTotalAmount}
      />
      {/* Table with Casino Transactions Info */}
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders(userDetail).map((c) => (
              <th key={c}>
                {c}
                {sortArrowIcon(c, sortBy, setSortBy, over, setOver, 'casinoTransaction')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {transactions && !loading &&
            transactions?.rows?.map(
              ({
                casinoTransactionId,
                User,
                createdAt,
                actionType,
                amountType,
                amount,
                status,
                beforeBalance,
                afterBalance,
                nonCashAmount,
                userBonus,
                MasterCasinoGames,
                jackpotContribution,
                moreDetails
              }) => {
                return (
                  <tr key={`casino-transaction-list ${casinoTransactionId}`}>
                    <td>{casinoTransactionId}</td>
                    {!userDetail &&
                      // <td>
                      //   {(userDetail && !adminPermissions?.Users?.includes('RUI'))
                      //     ? <span className='blur-contant'>test@yopmail.com</span>
                      //     : (
                      //       <Trigger message={User?.email}>
                      //         <span className='d-inline-block text-truncate width-spacing'>
                      //           {User?.email}
                      //         </span>
                      //       </Trigger>
                      //       )}
                      // </td>
                      <td
                        onClick={() =>
                          window.open(`/${isTenant ? 'tenant' : 'super-admin'}/users/details/${User?.userId}`, '_blank')}
                        className='text-link cursor-pointer'
                      >{User.userId}
                      </td>}

                    <td>
                      <Trigger message={MasterCasinoGames?.name}>
                        <span
                          className='d-inline-block text-truncate width-spacing'
                        >
                          {MasterCasinoGames?.name}
                        </span>
                      </Trigger>
                    </td>

                    <td>{MasterCasinoGames?.MasterCasinoProvider.name}</td>
                    <td>
                      {actionType}
                      {!!moreDetails?.bannedGamesListId && (
                        <Trigger message={`Banned Game List ID: ${moreDetails?.bannedGamesListId}`}>
                          <div className='d-inline-block ms-2'>
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </div>
                        </Trigger>)}
                    </td>
                    <td>{`${amount} ${User?.currencyCode}`}</td>
                    <td>{`${nonCashAmount} ${User?.currencyCode}`}</td>
                    <td>{AMOUNT_TYPE[amountType].label} {getBonusId(userBonus?.bonusId)}</td>
                    <td>{`${beforeBalance?.toFixed(2)} ${User?.currencyCode}`}</td>
                    <td>{`${afterBalance?.toFixed(2)} ${User?.currencyCode}`}</td>
                    <td>{`${moreDetails?.jackpotAmount?.toFixed(2) || 0} ${User?.currencyCode}` + ` (${jackpotContribution?.toFixed(2) || 0} EUR)`}</td>
                    <td>{statusType?.[parseInt(status) + 1].label}</td>
                    <td>{createdAt ? getDateTime(createdAt) : 'NA'}</td>
                  </tr>
                )
              })}
          {transactions?.count === 0 && !loading && (
            <tr>
              <td colSpan={11} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {
        transactions?.count > 0 && !loading && (
          <PaginationComponent
            page={transactions?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )
      }
    </>
  )
}

export default CasinoTransactionsList
