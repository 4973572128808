import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isTenant } from '../../utils/constants'
import { getUIComponents } from '../../utils/apiCalls'
import { updateUIComponentsStart } from '../../store/redux-slices/fetchData'

const useUIManagement = (pageName) => {
  const { tenantDetails } = useSelector(state => state.login)
  const { uiComponentsLoading } = useSelector(state => state.fetch)
  const [pageData, setPageData] = useState({ rows: [], count: 0 })
  const [loading, setLoading] = useState(isTenant)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const dispatch = useDispatch()

  const getComponents = async () => {
    const { data } = await getUIComponents({ tenantId: isTenant ? tenantDetails?.tenantId : selectedPortal, page: pageName })
    const sortedComponents = data?.data?.data?.components.sort((a, b) => a.priority - b.priority)
    setPageData({ rows: sortedComponents, count: data?.data?.data?.components?.length })
    setLoading(false)
  }

  useEffect(() => {
    if (!isTenant && !selectedPortal) {
      return
    }

    if (!pageName) {
      return
    }

    if (selectedPortal) {
      setLoading(true)
    }

    getComponents()
  }, [selectedPortal])

  const reorder = (arr, indexToRemove, newIndex) => {
    const [item] = arr.splice(indexToRemove, 1)
    arr.splice(newIndex, 0, item)
    return arr
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    reorder(
      pageData.rows,
      result.source.index,
      result.destination.index
    )
  }

  const handleVisibilityToggle = (index) => {
    const item = pageData.rows?.[index]
    const updatedItem = { ...item, isVisible: !item.isVisible }
    setPageData({ ...pageData, rows: pageData.rows.toSpliced(index, 1, updatedItem) })
  }

  const updateComponents = () => {
    const requestBody = pageData.rows.map((item, index) => ({ ...item, priority: index + 1 }))
    dispatch(updateUIComponentsStart({
      tenantId: isTenant ? tenantDetails?.tenantId : Number(selectedPortal),
      page: pageName,
      components: { page: pageName, components: requestBody }
    }))
  }

  return {
    tenantDetails,
    uiComponentsLoading,
    pageData,
    loading,
    setLoading,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    getComponents,
    onDragEnd,
    handleVisibilityToggle,
    updateComponents,
    isTenant
  }
}

export default useUIManagement
