import { useEffect, useState } from 'react'
import { CRM_ELIGIBLE_FOR_LAUNCH, isTenant } from '../../../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { updateCrmIntegrationStart } from '../../../../store/redux-slices/tenantcredentials'
import { getCrmIntegration, getLaunchCrmDetails } from '../../../../utils/apiCalls'
import { toast } from '../../../../components/Toast'
import useCheckPermission from '../../../../utils/checkPermission'
import { decryptCredentials, encryptCredentials, getItem } from '../../../../utils/storageUtils'

const useCrmSettings = () => {
  const { crmIntegrationKeys } = useSelector(state => state.tenantcredentials)
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedCrm, setSelectedCrm] = useState(tenantDetails?.tenantConfig?.crmConfig?.crmIntegrated || '')
  const dispatch = useDispatch()
  const [myKeys, setMyKeys] = useState({})
  const [tenantData, setTenantData] = useState(null)
  const [showLaunchButton, setShowLaunchButton] = useState(false)
  const { isHidden } = useCheckPermission()
  const tenantId = getItem('tenant-id')

  useEffect(() => {
    if (!isTenant && selectedPortal) {
      getCrmIntegration(selectedPortal)
        .then((response) => {
          if (response?.data?.data?.tenantConfiguration?.crmConfig?.crmIntegrated) {
            const tenantConfig = response?.data?.data?.tenantConfiguration?.crmConfig
            setTenantData(tenantConfig)
            setMyKeys(Object.keys(tenantConfig).filter((key) => key !== 'crmIntegrated'))
            if (tenantConfig?.crmIntegrated === CRM_ELIGIBLE_FOR_LAUNCH) {
              setShowLaunchButton(true)
            }
          } else {
            setTenantData(null)
            setShowLaunchButton(false)
            setMyKeys({})
          }
        })
        .catch(() => {
          toast('Not able to fetch tenant data', 'error')
          setSelectedPortal('')
        })
    }
  }, [selectedPortal])

  useEffect(() => {
    if (tenantDetails?.tenantConfig?.crmConfig?.crmIntegrated) {
      setMyKeys(crmIntegrationKeys?.[tenantDetails?.tenantConfig?.crmConfig?.crmIntegrated])

      if (tenantDetails?.tenantConfig?.crmConfig?.crmIntegrated === CRM_ELIGIBLE_FOR_LAUNCH) {
        setShowLaunchButton(true)
      }
    } else {
      setMyKeys(crmIntegrationKeys?.[selectedCrm])
    }
  }, [selectedCrm])

  const getInitialValues = () => {
    if (!isTenant) {
      if (tenantData) {
        return ({ selectedCrm: tenantData?.crmIntegrated, ...tenantData })
      }
    } else if (myKeys?.length) {
      return ({
        selectedCrm: selectedCrm || '',
        ...myKeys.reduce((acc, currValue) => ({
          ...acc,
          [currValue]: tenantDetails?.tenantConfig?.crmConfig?.[currValue] ? decryptCredentials(tenantDetails?.tenantConfig?.crmConfig?.[currValue], true) : ''
        }), {})
      })
    }

    return ({ selectedCrm: '' })
  }

  const handleSubmit = (crm, keys) => {
    const encryptedKeys = {}
    Object.entries(keys).forEach(([key, value]) => {
      encryptedKeys[key] = encryptCredentials(value)
    })

    dispatch(updateCrmIntegrationStart({
      data: {
        tenantId: isTenant ? tenantId : selectedPortal, crmConfig: { crmIntegrated: crm, ...encryptedKeys }
      },
      isTenant
    }))

    if (crm === CRM_ELIGIBLE_FOR_LAUNCH) {
      setShowLaunchButton(true)
    }
  }

  const handleLaunchCrm = () => {
    getLaunchCrmDetails(isTenant ? tenantId : selectedPortal)
      .then((response) => {
        if (response?.data?.data?.getToken?.token || response?.data?.data?.token) {
          const token = response?.data?.data?.getToken?.token || response?.data?.data?.token
          window.open(`${process.env.REACT_APP_CRM_REDIRECTION_URL}?token=${token}`, '_blank', 'noopener,noreferrer')
        } else {
          toast('Cannot launch CRM', 'error')
        }
      })
      .catch((err) => {
        console.error('Error', err)
      })
  }

  return {
    crmIntegrationKeys,
    tenantDetails,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    selectedCrm,
    setSelectedCrm,
    myKeys,
    setMyKeys,
    tenantData,
    setTenantData,
    showLaunchButton,
    setShowLaunchButton,
    getInitialValues,
    handleSubmit,
    handleLaunchCrm,
    isHidden
  }
}

export default useCrmSettings
