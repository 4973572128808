import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button, Form as BForm, Spinner } from '@themesberg/react-bootstrap'
import './reorderUIComponents.scss'
import useCheckPermission from '../../utils/checkPermission'
import { Card } from 'react-bootstrap'

const ReorderUIComponents = ({
  onDragEnd, loading, updateComponents, handleVisibilityToggle, data, droppableId
}) => {
  const { isHidden } = useCheckPermission()

  return (
    <Card body className='reorder-ui-container'>
      <h5>Reorder and toggle visibility for components</h5>

      <div>
        <div className='d-flex justify-content-between mt-3'>
          {[
            'Priority',
            'Name',
            'Visibility'
          ].map((h) => (
            <div key={h}>{h}</div>
          ))}
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableId}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {data?.map(
                  (item, idx) => (
                    <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                      {provided => (
                        <div
                          className='draggable-item'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div>{idx + 1}</div>
                          <div>{item.displayName}</div>

                          <BForm.Check
                            type='switch'
                            name='hideWidget'
                            disabled={isHidden({ module: { key: 'UIManagement', value: 'U' } })}
                            checked={item?.isVisible || false}
                            onChange={() => handleVisibilityToggle(idx)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button
          className='m-1 mt-3'
          size='sm'
          variant='success'
          onClick={updateComponents}
          disabled={isHidden({ module: { key: 'UIManagement', value: 'U' } })}
        >Submit

          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </Button>
      </div>
    </Card>
  )
}

export default ReorderUIComponents
