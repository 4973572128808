import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getBannedGamesListDetailStart } from '../../../store/redux-slices/superAdminTransactions'
// Custom hook for managing banned players
const useBannedGameDetail = ({ isTenant, bonusDetail }) => {
  const dispatch = useDispatch() // Hook to access Redux dispatch

  // State variables for pagination and filtering
  const [limit, setLimit] = useState(15) // Number of banned players to display per page

  const [pageNo, setPageNo] = useState(1) // Current page number

  const [search, setSearch] = useState('') // Search query for filtering banned players

  const { loading, getBannedGamesListDetail } = useSelector((state) => state.superAdminTransactions)

  const [listBannedGames, setListBannedGames] = useState([])

  const totalPages = Math.ceil(getBannedGamesListDetail?.gameDetail?.count / limit)

  const getBannedGamesList = () => {
    if (bonusDetail?.other.bannedGamesListId) {
      dispatch(getBannedGamesListDetailStart({
        isTenant,
        adminId: bonusDetail?.adminId,
        bannedGamesListId: bonusDetail?.other.bannedGamesListId,
        limit,
        pageNo,
        providerId: '',
        search
      }))
    }
  }

  // Effect to fetch banned players when dependencies change
  useEffect(() => {
    getBannedGamesList() // Fetch banned players on limit, page, client, or portal change
  }, [limit, pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getBannedGamesList()
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  // Update the local state with the list of banned games whenever the fetched details change
  useEffect(() => {
    if (getBannedGamesListDetail?.gameDetail?.rows) {
      setListBannedGames(getBannedGamesListDetail.gameDetail.rows)
    }
  }, [getBannedGamesListDetail])

  // Return values and functions to be used in the component
  return {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    search,
    setSearch,
    totalPages,
    loading,
    listBannedGames,
    setListBannedGames,
    getBannedGamesListDetail
  }
}

export default useBannedGameDetail // Exporting the custom hook for use in other components
